<template>
    <a-modal v-if="homeDetails.unit && units[homeDetails.unit]" :visible="visible" :centered="true" :footer="null" @cancel="onClose" :width="900">
        <div class="dF aC">
            <h4 class="mr-5" style="margin-bottom:0">{{`${units[homeDetails.unit].unitGroup.name} ${units[homeDetails.unit].name}`}}</h4>
            <div style="color:#9EA0A5; font-size:20px">Model Type</div>
            <div class="ml-3" style="color:#000; font-size:20px">{{units[homeDetails.unit].unitGroup.type}}</div>
        </div>
        <div class="w-full mt-3">
            <img class="w-full" :style="[featureImage != '' ? {'object-fit':'contain'} : {'object-fit':'cover'}]" style="max-height: 500px" :src="featureImage != '' ? featureImage : units[homeDetails.unit] && units[homeDetails.unit].image ? units[homeDetails.unit].image : ''" />
        </div>
        <div class="dF aC mt-3" style="font-size:15px; color:#000" v-if="units[homeDetails.unit].packages && units[homeDetails.unit].packages[selectedPackage]">
            <div>{{`${units[homeDetails.unit].packages[selectedPackage].beds} Beds`}}</div> &emsp;
            <div>|</div> &emsp;
			<template v-if="units[homeDetails.unit] && units[homeDetails.unit].packages && units[homeDetails.unit].packages[selectedPackage].dens && units[homeDetails.unit].packages[selectedPackage].dens > 0">
				<div>{{`${units[homeDetails.unit].packages[selectedPackage].dens} Dens`}}</div> &emsp;
				<div>|</div> &emsp;
			</template>
			<template v-if="units[homeDetails.unit]?.packages[selectedPackage]?.baths">
				<div>{{`${units[homeDetails.unit].packages[selectedPackage].baths} Baths`}}</div> &emsp;
				<div>|</div> &emsp;
			</template>
			<template v-if="units[homeDetails.unit]?.packages[selectedPackage]?.powderRooms">
				<div>{{`${units[homeDetails.unit].packages[selectedPackage].powderRooms} Powdr`}}</div> &emsp;
				<div>|</div> &emsp;
			</template>
			<template v-if="units[homeDetails.unit]?.packages[selectedPackage]?.garages">
				<div>{{`${units[homeDetails.unit].packages[selectedPackage].garages} Car Garages`}}</div> &emsp;
				<div>|</div> &emsp;
			</template>
			<template v-if="units[homeDetails.unit]?.packages[selectedPackage]?.stories">
				<div>{{`${units[homeDetails.unit].packages[selectedPackage].stories} Stories`}}</div> &emsp;
				<div>|</div> &emsp;
			</template>
			<template v-if="units[homeDetails.unit]?.packages[selectedPackage]?.sqft">
				<div>{{`${units[homeDetails.unit].packages[selectedPackage].sqft} Sq. Ft.`}}</div>
			</template>
        </div>
        <hr />
        <div class="dF">
            <div style="width:60%; border-right: 1px solid #000; padding-right:20px">
                <div>
                    <h5>Floorplan Package</h5>
                    <div class="mt-3" style="display:grid; grid-template-columns: repeat(3, minmax(0, 1fr)); gap: 1rem">
                        <div @click="selectedPackage = packI" style="border-radius:4px; text-align:center; cursor:pointer" class="py-1 px-2" :style="packI == selectedPackage ? 'border:1px solid var(--orange); color: var(--orange)':'border:1px solid #D0C9D6; background-color:#F2F2F1; color:#000'" v-for="(pack, packI) in units[homeDetails.unit].packages" :key="pack.id">{{pack.name}}</div>
                    </div>
                </div>
                <div class="mt-5" v-if="units[homeDetails.unit]?.packages[selectedPackage]?.floors">
                    <h5>Floor</h5>
                    <div class="mt-3" style="display:grid; grid-template-columns: repeat(3, minmax(0, 1fr)); gap: 1rem">
                        <div @click="selectedFloor(floor)" v-for="floor in units[homeDetails.unit].packages[selectedPackage].floors" :key="floor.id" class="py-1 px-2" style="border-radius:4px; text-align:center; cursor: pointer;" :style="floor.id == selectedFloorId ? 'border:1px solid var(--orange); color: var(--orange)':'border:1px solid #D0C9D6; background-color:#F2F2F1; color:#000'">{{floor.name}}</div>
                    </div>
                </div>
            </div>
            <div style="width:40%; padding-left:20px; flex-direction:column;" class="jSB dF">
                <div>
                    <div class="dF jSB aC">
                        <div style="color:#40454C">Elevation Price</div>
                        <div>{{`$${units[homeDetails.unit] && units[homeDetails.unit].packages && units[homeDetails.unit].packages[selectedPackage] && units[homeDetails.unit].packages[selectedPackage].price && units[homeDetails.unit].packages[selectedPackage].price.toLocaleString('US')}`}}</div>
                    </div>
                    <div class="dF jSB mt-4 aC">
                        <div style="color:#40454C">Lot Condition/Premium</div>
                        <div>{{`$${getPremiums().toLocaleString('US')}`}}</div>
                    </div>
                </div>
                <div class="dF jSB aC" style="color:#000; font-size:20px">
                    <div>Total</div>
                    <div>{{`$${(getPremiums()+units[homeDetails.unit].packages[selectedPackage].price).toLocaleString('US')}`}}</div>
                </div>
            </div>
        </div>
        <div class="w-full mt-5">
            <div>
                <div class="custom-scrollbar" style="height:100%; overflow-x: scroll;">
                    <div class="dF aE" style="width:100%;">
                        <div @click="showDetails(unit)" v-for="(unit, unitI) in filterUnits()" :key="unit+unitI" :class="unitI != 0 ? 'ml-2' : ''">
                            <div class="p-3" style="cursor:pointer; width:200px; box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1); border:1px solid #E4E7EB; border-radius:4px">
                                <div class="relative" style="height:100%; width:100%">
                                    <img style="width:100%; height:100px; object-fit:cover" :src="units[unit] && units[unit].image ? units[unit].image : ''" />
                                </div>
                                <h6 class="mt-3" v-if="units[unit] && units[unit].unitGroup">{{`${units[unit].unitGroup.name} ${units[unit].name}`}}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="(lots[homeDetails.lot].status == 'available' || lots[homeDetails.lot].status == 'hold') && !lots[homeDetails.lot].transactionId" class="mt-5 dF jE">
            <a-button size="large" type="primary" @click="makeTransaction(lots[homeDetails.lot], units[homeDetails.unit])">MAKE A TRANSACTION  </a-button>
        </div>
    </a-modal>
</template>

<script>
export default {
    data() {
        return{
            selectedPackage:0,
            selectedFloorId:'',
            featureImage:''
        }
    },
    computed:{
        visible() {
            return this.$store.state.inventory.homeDetails.visible
        },
        units() {
            return this.$store.state.inventory.units
        },
        homeDetails() {
			console.log('HOME DETAILSSS', this.$store.state.inventory.homeDetails)
            return this.$store.state.inventory.homeDetails
        },
        lots() {
            return this.$store.state.inventory.lots
        },
        premiums() {
            return this.$store.state.inventory.premiums
        },
		allPremiums(){
			let premiums = []
			Object.values(this.premiums).forEach(x => {
				premiums.push(x.id)
			})
			return premiums
		}
    },
    methods:{
		makeTransaction(lot, unit) {
            const pkg = this.units[unit.id]?.packages[this.selectedPackage]?.id
			window.open(`https://transactions.bildhive.${this.$tld}/new?lot=${lot.id}&elevation=${unit.id}&package=${pkg}`, '_blank');
        },
        selectedFloor(floor){
            if (floor.id == this.selectedFloorId){
                this.selectedFloorId = ''
                this.featureImage = ''
            } else {
                this.selectedFloorId = floor.id
                this.featureImage = floor.image
            }
        },
        showDetails(id) {
            this.selectedPackage = 0
            this.$store.commit('OPEN_HOME_DETAILS', {units: this.homeDetails.units, unit:id, lot:this.homeDetails.lot})
        },
        filterUnits() {
            let units = []
            if (this.homeDetails.units && this.homeDetails.units != '' && this.homeDetails.units.length) {
                units = this.homeDetails.units.filter(x => x != this.homeDetails.unit)
            }
            return units
        },
        onClose() {
            this.$store.commit('CLOSE_HOME_DETAILS')
        },
        getPremiums() {
            let price = 0
            let lot = this.lots[this.homeDetails.lot]
            if (lot.other && lot.other.premiums && Object.keys(lot.other.premiums).length) {
                Object.keys(lot.other.premiums).forEach(key => {
                    if (lot.other.premiums[key].active && this.allPremiums.includes(lot.other.premiums[key].id) && lot.other.premiums[key].price && lot.other.premiums[key].price != 0) {
                        price = price + lot.other.premiums[key].price
                    }
                })
            }
            return price
        }
    }
}
</script>

<style>

</style>
