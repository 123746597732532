<template>
    <a-modal
        v-if="unit"
        :visible="visible"
        :centered="true"
        :footer="null"
        @cancel="onClose"
        :width="900"
    >
        <div class="dF aC jSB mr-5">
            <div style="font-weight: bold; font-size: 25px">
                {{ `Unit ${unit.unitNumber}  ${unit.name}` }}
            </div>
			<div class="dF aC">
				<div
					v-if="unit.packages[0].floors.length"
					style="color: #9ea0a5; font-size: 20px"
				>
					Floor Plan:
				</div>
				<div
					v-if="unit.packages[0].floors.length"
					class="ml-3"
					style="color: #000; font-size: 20px"
				>
					{{
					selectedFloor === 0
					? "Main Floor"
					: `Level ${selectedFloor + 1} Floor`
					}}
				</div>
			</div>
            <div class="dF aC">
				<div
					v-if="unit.allocatedTo"
					style="color: #9ea0a5; font-size: 20px"
				>
					Allocated To:
				</div>
				<div
					v-if="unit.allocatedTo"
					class="ml-3"
					style="color: #000; font-size: 20px"
				>
					{{unit.allocatedTo.firstName}} {{unit.allocatedTo.lastName}}
				</div>
			</div>
        </div>
        <div class="w-full mt-3">
            <a-carousel
                arrows
                :after-change="onFloorChange"
                class="carousel-modal"
            >
                <template #prevArrow>
                    <div
                        class="custom-slick-arrow"
                        style="left: 10px; z-index: 1"
                    >
                        <svg
                            style="width: 20px; height: 20px; color: black"
                            viewBox="64 64 896 896"
                            data-icon="arrow-left"
                            fill="currentColor"
                            aria-hidden="true"
                            focusable="false"
                            class=""
                        >
                            <path
                                d="M872 474H286.9l350.2-304c5.6-4.9 2.2-14-5.2-14h-88.5c-3.9 0-7.6 1.4-10.5 3.9L155 487.8a31.96 31.96 0 0 0 0 48.3L535.1 866c1.5 1.3 3.3 2 5.2 2h91.5c7.4 0 10.8-9.2 5.2-14L286.9 550H872c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z"
                            ></path>
                        </svg>
                    </div>
                </template>
                <template #nextArrow>
                    <div class="custom-slick-arrow" style="right: 10px">
                        <svg
                            style="width: 20px; height: 20px; color: black"
                            focusable="false"
                            class=""
                            data-icon="arrow-right"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true"
                            viewBox="64 64 896 896"
                        >
                            <path
                                d="M869 487.8L491.2 159.9c-2.9-2.5-6.6-3.9-10.5-3.9h-88.5c-7.4 0-10.8 9.2-5.2 14l350.2 304H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h585.1L386.9 854c-5.6 4.9-2.2 14 5.2 14h91.5c1.9 0 3.8-.7 5.2-2L869 536.2a32.07 32.07 0 000-48.4z"
                            ></path>
                        </svg>
                    </div>
                </template>
                <img
                    :src="floor.image"
                    v-for="floor in unit.packages[0].floors"
                    :key="floor.id"
                />
            </a-carousel>
        </div>
        <div class="dF aC mt-5" style="font-size: 15px; color: #000">
            <div>{{ `${unit.packages[0].beds} Beds` }}</div>
            &emsp;
            <div>|</div>
            &emsp;
            <div>{{ `${unit.packages[0].baths} Baths` }}</div>
            &emsp;
            <div>|</div>
            &emsp;
			<div>{{ `${unit.packages[0].powderRooms || 0} Powder Rooms` }}</div>
            &emsp;
            <div>|</div>
            &emsp;
            <div>{{ `${unit.packages[0].sqft} Sq. Ft.` }}</div>
            &emsp;
            <div></div>
            &emsp;&emsp;
            <div>{{ `Exposure: ${unit.packages[0].exposure}` }}</div>
            <div></div>
            &emsp;&emsp;
            <div v-if="unit.packages[0].price && unit.packages[0].sqft">
                {{
                    `Price Per Sq.Ft.: $${(unit.packages[0].price/unit.packages[0].sqft).toLocaleString(
                        "US"
                    )}`
                }}
            </div>
        </div>
        <hr />
        <div class="dF">
            <div
                style="
                    width: 60%;
                    border-right: 1px solid #000;
                    padding-right: 20px;
                "
            >
                <div>
                    <h5>Unit Premiums</h5>
                    <div v-if="checkPremiums()" class="grid-display-4">
                        <template
                            v-for="(prem, pkey, pI) in unit.packages[0].other
                                .premiums"
                        >
                            <div
                                v-if="
                                    condoPremiums.hasOwnProperty(pkey) &&
                                    prem.active
                                "
                                :key="prem + pkey + pI"
                            >
                                <div style="font-weight: bold">
                                    {{ condoPremiums[pkey].name }}
                                </div>
                                <div>
                                    ${{
                                        unit.packages[0].other.premiums[pkey].value.toLocaleString("US")
                                    }}
                                </div>
                            </div>
                        </template>
                    </div>
                    <div v-else style="font-weight: bold">No Premiums</div>
                </div>
                <div class="mt-5">
                    <h5>Add Ons</h5>
                    <div v-if="addOns && addOns.length" class="grid-display-4">
                        <div
                            v-for="(addOn, index) in addOns"
                            :key="addOn.id + index"
                        >
                            <div style="font-weight: bold">
								{{ addOn.type }}: {{ addOn.name }}
                            </div>
                            <div v-if="addOn.price">${{ addOn.price.toLocaleString("US") }}</div>
							<div v-else>No price</div>
                        </div>
                    </div>
                    <div v-else style="font-weight: bold">No Add Ons</div>
                </div>
            </div>
            <div
                style="width: 40%; padding-left: 20px; flex-direction: column"
                class="jSB dF"
            >
                <div>
                    <div class="dF jSB aC">
                        <div style="color: #40454c">Unit Price</div>
                        <div>
                            {{
                                `$${unit.packages[0].price && unit.packages[0].price.toLocaleString(
                                    "US"
                                )}`
                            }}
                        </div>
                    </div>
                    <div class="dF jSB mt-3 aC">
                        <div style="color: #40454c">Unit Premium Price</div>
                        <div>
                            {{ `$${totalPremiumPrice && totalPremiumPrice.toLocaleString("US")}` }}
                        </div>
                    </div>
                    <div class="dF jSB mt-3 aC">
                        <div style="color: #40454c">Unit Add On Price</div>
                        <div>
                            {{ `$${totalAddOnsPrice && totalAddOnsPrice.toLocaleString("US")}` }}
                        </div>
                    </div>
                </div>
                <div
                    class="dF jSB aC mt-3"
                    style="color: #000; font-size: 20px"
                >
                    <div>Total</div>
                    <div>${{ totalPrice() }}</div>
                </div>
            </div>
        </div>
        <div v-if="['available', 'hold'].includes(unit.salesStatus) && $userType !== 'agent'" class="mt-3 dF jE aC">
            <a-button size="large" type="primary" @click="makeTransaction(unit)"
                >MAKE A TRANSACTION</a-button
            >
        </div>
		<div v-if="['available', 'allocated'].includes(unit.salesStatus) && $userType === 'agent'" class="mt-3 dF jE aC">
            <a-button size="large" type="primary" @click="createWorksheet(unit)"
                >FILL UP WORKSHEET</a-button
            >
        </div>
    </a-modal>
</template>

<script>
export default {
    data() {
        return {
            selectedFloor: 0,
        };
    },
    computed: {
        visible() {
            return this.$store.state.inventory.unitDetails.visible;
        },
        unit() {
            return this.$store.state.inventory.unitDetails.unit;
        },
        condoPremiums() {
            return this.$store.state.inventory.condoPremiums;
        },
        addOns() {
            const totalAddOns = [];
            const unitAddOns =
                this.unit.packages[0].other &&
                this.unit.packages[0].other.addons;
            if (unitAddOns) {
                if (unitAddOns.parking && unitAddOns.parking.length) {
                    totalAddOns.push(
                        ...unitAddOns.parking.map((p) => {
                            return { ...p, type: "Parking spot" };
                        })
                    );
                }
                if (unitAddOns.lockers && unitAddOns.lockers.length) {
                    totalAddOns.push(...unitAddOns.lockers.map((l) => {
                            return { ...l, type: "Locker" };
                        }));
                }
                if (unitAddOns.bikeRacks && unitAddOns.bikeRacks.length) {
                    totalAddOns.push(...unitAddOns.bikeRacks.map((b) => {
                            return { ...b, type: "Bike Rack" };
                        }));
                }
            }

            return totalAddOns;
        },
        totalAddOnsPrice() {
            return this.addOns.reduce(
                (previousValue, currentValue) =>
                    previousValue + currentValue.price,
                0
            );
        },

        totalPremiumPrice() {
            const premiums =
                this.unit.packages[0].other &&
                this.unit.packages[0].other.premiums &&
                Object.values(this.unit.packages[0].other.premiums);

            return premiums.reduce((previousValue, currentValue) => {
                if (currentValue.active) {
                    return previousValue + Number(currentValue.value);
                }
                return previousValue;
            }, 0);
        },
    },
    methods: {
        makeTransaction(unit) {
			window.open(`https://transactions.bildhive.${this.$tld}/new?floor=${unit.unitGroup && unit.unitGroup.id}&unit=${unit.id}`, '_blank');
        },

		createWorksheet(unit) {
			window.open(`https://worksheets.bildhive.${this.$tld}/create?unit=${unit.id}`, '_blank');
        },

        onFloorChange(floorIndex) {
            this.selectedFloor = floorIndex;
        },

        onClose() {
            this.$store.commit("CLOSE_UNIT_DETAILS");
        },

        totalPrice() {
            return (
                (this.unit.packages[0].price || 0) +
                (this.totalPremiumPrice || 0) +
                (this.totalAddOnsPrice || 0)
            ).toLocaleString("US");
        },

        checkPremiums() {
            let containsPremiums = false;
			if (!this.unit.packages || !this.unit.packages[0] || !this.unit.packages[0].other || !this.unit.packages[0].other.premiums) {
				return containsPremiums
			}
            Object.values(this.unit.packages[0].other.premiums).forEach(
                (prem) => {
                    if (prem.active) {
                        containsPremiums = true;
                        return;
                    }
                }
            );
            return containsPremiums;
        },
    },
};
</script>
<style lang="scss">
.carousel-modal >>> .slick-slide {
    text-align: center;
    line-height: 160px;
    background: #364d79;
    overflow: hidden;
}
.carousel-modal .custom-slick-arrow {
    background: white;
    border: 7px solid white;
    box-sizing: content-box;
    border-radius: 100%;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
    &:hover {
        background: white;
    }
}
.carousel-modal .slick-track {
    display: flex;
    align-items: center;
}

.carousel-modal >>> .slick-slide h3 {
    color: #fff;
}
.carousel-modal {
    .slick-dots li button {
        background: var(--med-gray);
    }
    .slick-dots li.slick-active button {
        background: gray;
    }
}
</style>
