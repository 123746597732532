import { reactive,computed, } from "vue";
import { createPopper } from './popper.js';

const siteplanApp = ({dom,options,spData,initCallback,onEvent,onFilter,hideHold,unitForSiteplan}) => {

    const drawLot = {
        apiURL:`${window.location.href.includes("com") ? 'https://api.bildhive.com' : 'https://api.bildhive.dev'}`,
        hideColor:'rgba(200,200,200,1)',
		highlightShapes:[],
		dotFraction:4,
		colorLookup:{
			available:'green',
			inventory:'#3395c6',
			sold:'red',
			conditional:'#9693e8',
			soldConditional:'#9693e8',
			hold:'#fece5b',
			notreleased:'#3f3356',
			allocated: "var(--primary)",
			approved_for_reservation: "#134F5C",
			reserved: "#419E00"
		},
		statusNames:{
			available:'Available',
			inventory:'Inventory',
			sold:'Sold',
			conditional:'Conditional',
			soldConditional:'Sold conditional',
			hold:'Hold',
			notreleased:'Not released',
			allocated: "Allocated",
			approved_for_reservation: "Approved",
			reserved: "Reserved"
		},
		anim:{
			speed:'medium',
			amount:0.5,
			leader:'',
			shapes:{},
		},
		iSlugs:{},
		developerInfo:{},
		spURL:'',
		touchInit:0,
		showTags:[],
		socketIds:{},
		instance:'',
		selectedStatuses:[],
		sockets:{},
		plans:{},
		updatedLots:{},
		lots:{},
		leftBar:true,
		instances:{},
		mobileMode:false,
		siteplanOverlay:null,
		hasError:false,
		filtered1:false,
		filtered2:false,
		filteredShapes:[],
		showLots:true,
		showAmenity:true,
		allFilter1:[],
		allFilter2:[],
		mouseWheelZoom:false,
		mouseWheelAssist:13,
		zooming:false,
		siteplan:{},
		parentElement:null,
		img: null,
		imgUrl: null,
		shape: null,
		dotRadius: 0,
		shapes: null,
		width: null,
		height: null,
		canvas: null,
		ctx: null,
		mouseX:null,
		mouseY:null,
		mouseOver:null,
		isMouseDown:false,
		isMouseUp:true,
		initialPinchDistance:null,
		mouseDownShape:null,
		isMousePan:false,
		siteplanImage: new Image,
		lotImage: null,
		siteplanDrawn:false,
		camera:{nextZoom:1,zoom:1,x:0,y:0,},
		originalCamera:{x:0,y:0,},
		dragStart:{x:0,y:0,},
		MAX_ZOOM:12,
		MIN_ZOOM:1,
		SCROLL_SENSITIVITY:0.0005,
		moveY:0,
		moveX:0,
		boundaries:{
			top:0,
			bottom:0,
		},
		landscape:false,
		pan:{
			left:false,
			up:false,
			down:false,
			right:false,
			lastX:0,
			lastY:0,
		},
		updated:{
			it:0,
			ib:0,
			iw:0,
			ih:0,
		},
		performedAction:false,
		timerId:null,

		selectedElev:null,
        isImage(url) {
            return new Promise( (res,rej) => {
                let image = new Image()
                image.onload = e => {
                  console.log('EEEEE',e)
                  res(true)
                }
                image.onerror = e => {
                    console.log('errerer',e)
                     rej(false)
                }
                image.src = url

            })
        },
        debounceFunction:(event,func, delay) => {
            // Cancels the setTimeout method execution
            clearTimeout(drawLot.timerId)

            // Executes the func after delay time.
            drawLot.timerId  =  setTimeout(() => func(event), delay)
        },
        throttleFunction:(event,func, delay) => {
            if (drawLot.timerId) {
                return
            }

            // Schedule a setTimeout after delay seconds
            drawLot.timerId  =  setTimeout(function () {
                func(event)
                drawLot.timerId  =  undefined;
            }, delay)
        },
        analyzeLots(){
            this.lots
        },
        get_polygon_centroid: (pts,xKey = 'x', yKey = 'y') => {
            var first = pts[0], last = pts[pts.length-1];
            if (first[xKey] != last[xKey] || first[yKey] != last[yKey]) pts.push(first);

            var twicearea=0,
            x=0, y=0,
            nPts = pts.length,
            p1, p2, f;
            for ( var i=0, j=nPts-1 ; i<nPts ; j=i++ ) {
                p1 = pts[i]; p2 = pts[j];
                f = (p1[yKey] - first[yKey]) * (p2[xKey] - first[xKey]) - (p2[yKey] - first[yKey]) * (p1[xKey] - first[xKey]);
                twicearea += f;
                x += (p1[xKey] + p2[xKey] - 2 * first[xKey]) * f;
                y += (p1[yKey] + p2[yKey] - 2 * first[yKey]) * f;
            }
            f = twicearea * 3;
            return { x:x/f + first[xKey], y:y/f + first[yKey] };
        },
        reCoordinate(shape) {

            let { x, y, width, height } = shape

            let shapeY = this.p2({ y })
            let shapeX = this.p2({ x })
            shape.shapeX = shapeX + this.moveX
            shape.shapeY = shapeY + this.moveY
            shape.shapeW = this.p2({ x: width })
            shape.shapeH = this.p2({ y: height })
            if (this.dotRadius === 0) this.dotRadius = shape.shapeW
            if (shape.shapeW < this.dotRadius) this.dotRadius = shape.shapeW
            if (shape.shapeH < this.dotRadius) this.dotRadius = shape.shapeH



        },
        doPoints(shape){
            shape.points.forEach(point => {
                let {x,y}=point

                let pX = x / 100
                point.pX = (pX * shape.shapeW) + shape.shapeX

                let pY = y / 100
                point.pY = (pY * shape.shapeH) + shape.shapeY

            })

            var coordinates = shape.points,
                minx = coordinates[0].x,
                maxx = coordinates[0].x,
                miny = coordinates[0].y,
                maxy = coordinates[0].y;

            for (let index = 1; index < coordinates.length; index++) {
                if (coordinates[index].x < minx) minx = coordinates[index.x];
                if (coordinates[index].x > maxx) maxx = coordinates[index.x];
                if (coordinates[index].y < miny) miny = coordinates[index.y];
                if (coordinates[index].y > maxy) maxy = coordinates[index.y];
            }

            let result = this.get_polygon_centroid(shape.points,'pX','pY')
            let {x:cX = 0, y:cY = 0} = result
            shape.cX = cX
            shape.cX = cX
            shape.cY = cY
        },
        p2({ x = false, y = false }) {
            if (x) {
                let p = x / 100
                return p * this.imageWidth
            }
            if (y) {
                let p = y / 100
                return (p * this.imageHeight)
            }
        },
        plotLines(shape) {
			if (!shape.points) {
				this.hasError = true
			}

			let highlight = false
			if (this.highlightShapes.find(x => String(x) == String(shape.id))){
				highlight = true
			}


			let ctx = this.ctx

			ctx.lineWidth = "0.25";
			ctx.strokeStyle = 'rgba(0,0,0,0)'
			ctx.fillStyle = 'rgba(255,255,255,0)'


			//status color
			let colorLookup = this.colorLookup
			let status = shape.lot.status
			let color = colorLookup[status] || 'rgba(255,255,255,0)'

			if (!this.hideStatus && this.hideDots && status != 'available'){
				ctx.fillStyle = color
			}

			if (shape.hidden || (shape.inactive || (shape.lot.status === 'hold' && this.hideHold))){
				ctx.fillStyle = this.hideColor
			}

			let x0 = 0
			let y0 = 0
			shape.points.forEach(({ x,pX,pY,y }, cI) => {

				if (cI) return ctx.lineTo(pX, pY)
				x0 = pX
				y0 = pY
				ctx.moveTo(pX, pY)
			})

			ctx.save(); //save canvas state
			if (highlight){
				ctx.setLineDash([3, 1]);
				ctx.strokeStyle = 'rgba(255,255,255,1)'
				ctx.lineWidth = "1";
				ctx.fillStyle = "rgba(255,255,255,0.75)"
			}

			ctx.lineTo(x0, y0)

			ctx.closePath()

			if (!isNaN(this.mouseX) && !isNaN(this.mouseY) && !shape.hidden) {
				if (ctx.isPointInPath(this.mouseX, this.mouseY) && !shape.inactive){
					if (!this.isMousePan){
						this.mouseOver = shape
						if (this.mobileMode){
							ctx.lineWidth = "0.5";
						} else {
							ctx.lineWidth = "1.5";
						}
						// ctx.fillStyle = 'rgba(255,255,255,0.5)'
						ctx.strokeStyle = 'rgba(255,255,255,1)'

					}
				}
			}

			ctx.fill()
			ctx.stroke();
			ctx.restore(); //restore canvas state
			if (!(shape.hidden || (shape.inactive || (shape.lot.status === 'hold' && this.hideHold))) && !this.hideStatus && this.hideDots && status != 'available'){
				let text = `${shape.lot.name}`;
				ctx.save(); //save canvas state
				ctx.fillStyle = "rgba(255,255,255,1)";


				let mobileSize = this.fontSize * 0.05
				let desktopSize = this.fontSize * 0.10

				if (this.mobileMode){
					ctx.font = `bold ${mobileSize}rem Arial`;
				} else {
					ctx.font = `bold ${desktopSize}rem Arial`;
				}
				ctx.textAlign = "center";
				ctx.textBaseline = "middle";
				ctx.fillText(text, shape.cX, shape.cY);
				ctx.restore(); //restore canvas state
			}

			if (this.hideAvail && shape.lot.status === 'available') return
			if (shape.lot.status === 'hold' && this.hideHold) return
			if (!this.hideDots && !shape.hidden && !shape.inactive && shape.lot && shape.lot.status && shape.lot.type === 'lot'){

				let dotRadius = this.useLotRadius && shape.dotRadius ? shape.dotRadius : this.dotRadius
				ctx.save();
				ctx.beginPath();
				ctx.globalAlpha = 0.6;
				ctx.fillStyle = color
				ctx.lineWidth = "0.2";
				ctx.strokeStyle = 'white';

				// dotRadius:100, dotFraction:2
				let dotSize = dotRadius / this.dotFraction // 50
				let minDotSize = dotRadius / 2 // minDotSize = 50
				// let subtractingNumber = dotSize * 0.01

				let isLeader = true
				if (this.shapes[this.anim.leader] && this.shapes[this.anim.leader].inactive){

				} else if (this.anim.leader && this.anim.leader !== shape.id && this.anim.shapes[this.anim.leader] && this.anim.shapes[this.anim.leader].hasOwnProperty('delay')){
					theLeader = this.anim.shapes[this.anim.leader]
					isLeader = false
				}

				if (this.pulsateDots && isLeader){

					this.anim.leader = shape.id

					if (!this.anim.shapes[shape.id]) this.anim.shapes[shape.id] = {
						enabled:true,
						delay:100,
						size:1,
						reverse:false
					}


					let theShape = this.anim.shapes[shape.id]

					if (theShape.enabled){

						let amount = this.anim.amount
						// if (this.anim.speed === 'fast') amount = 0.02
						// if (this.anim.speed === 'slow') amount = 0.005

						if (theShape.size < 1) theShape.size = 1

						if (theShape.reverse){
							if ( theShape.size > 0) theShape.size += -1*amount
							else theShape.reverse = !theShape.reverse
						}

						if (!theShape.reverse) {
							if (theShape.size < 5) theShape.size += amount
							else theShape.reverse = !theShape.reverse
						}

						ctx.strokeStyle = `rgba(255,255,255,1)`
						ctx.fillStyle = color

						if (theShape.delay < 0) return theShape.enabled = false
						theShape.delay = theShape.delay - (this.anim.amount * 1)

					} else {

						ctx.strokeStyle = `rgba(255,255,255,1)`
						ctx.fillStyle = color

						if (theShape.delay > 99) return theShape.enabled = true
						theShape.delay = theShape.delay + (this.anim.amount * 1.8)

					}
				}

				let delay = this.anim.shapes[this.anim.leader] && this.anim.shapes[this.anim.leader].delay || 100
				// if (delay > 101) delay = 100
				dotSize = Math.abs(dotSize * ( delay / 100)) //
				ctx.arc(shape.cX, shape.cY, dotSize, 0, Math.PI * 2, true);

				ctx.fill()
				ctx.restore();
				ctx.stroke()
				ctx.closePath()
			}

		},
        highlightShape(shape) {
            let { x, y, width, height } = shape

            x = this.p2({ x })
            y = this.p2({ y })
            width = this.p2({ x: width })
            height = this.p2({ y: height })

            this.ctx.beginPath()
            this.ctx.rect(x, y, width, height)
            this.ctx.stroke()
        },
        getArrayBuffer(cv) {
            return new Promise((resolve, reject) => {
                cv.toBlob(blob => {

                    const reader = new FileReader();
                    reader.addEventListener('loadend', () => {
                        const arrayBuffer = reader.result;
                        return resolve(arrayBuffer)
                            //   bufferByteLen.textContent = arrayBuffer.byteLength + ' bytes.';

                        //   // Dispay Blob content in an Image.
                        //   const blob = new Blob([arrayBuffer], {type: mimeType});
                        //   imageOut.src = URL.createObjectURL(blob);
                    });

                    reader.readAsArrayBuffer(blob);

                }, 'image/jpeg', 1.0)
            })
        },
        doFilter2(dom){

            if (dom.dataset.hasOwnProperty('reset')){
                this.filtered2 = false
            }else if (dom.dataset.value){
                this.filtered2 = dom.dataset.value
            }
            this.doFilters()
        },
		filterByStatus(statuses){
			this.selectedStatuses = statuses;
			this.doFilters()
		},
        chooseFilter1(value = false){
            this.filtered1 = value
            this.doFilters()
        },
        doFilter1(dom){
            if (dom.dataset.hasOwnProperty('reset')){
                this.filtered1 = false
            } else if (dom.dataset.value){
                this.filtered1 = dom.dataset.value
            }
            this.doFilters()
			this.onFilter(dom)
			let filter1 = document.querySelector('[data-filter-1]')
			if (filter1){
				let currentActive = filter1.querySelector('.active')
				if (currentActive) currentActive.classList.remove('active')
				dom.classList.add('active')
			}
        },
        doFilters(){
            let shapes = Object.values(this.shapes).filter(x=>!x.inactive)

            if (!this.filtered1 && !this.filtered2) {
                this.filteredShapes = shapes.map(item => ({
                    ...item,
                    hidden:false
                }))
            }

            this.filteredShapes = shapes.map(shape => {
                shape.hidden = false
				shape.highlight = false
                if (shape.lot && shape.lot.type === 'info') return shape
                if (this.filtered1 &&  (!shape.filter1 || !shape.filter1.includes(this.filtered1)) ) shape.hidden = true
                if (this.filtered2 &&  (!shape.filter2 || !shape.filter2.includes(this.filtered2)) ) shape.hidden = true
                if (this.selectedStatuses[0] != 'total' && shape.lot.status && !this.selectedStatuses.includes(shape.lot.status)) shape.hidden = true
				return shape
            })
			console.log('SELECT TYPES', this.selectedStatuses)
            this.filteredShapes = [...this.filteredShapes,...this.inactiveShapes]
        },
        getOrientation(w,h){
            return w > h ? 'l' : w === h ? 's' : 'p'
        },
        async filterLotsByUnit(id, status = 'available') {
			return new Promise((resolve, reject) => {
				this.getArchitecturalRules(id)
				.then(data => {
					console.log('DATA COMING BACK FROM ARCHITECTURAL RULES', data);
					resolve(data);
				})
				.catch(error => {
					reject(error);
				});
			});
		},
        renderFilters(){
            let filter1 = document.querySelector('[data-filter-1]')
            this.filter1DOM = filter1

            let filter2 = document.querySelector('[data-filter-2]')
            this.filter2DOM = filter2
            if (document.querySelector('[data-filter-1]') && this.sectionStyle !== 'one') document.querySelector('[data-filter-1]').style.display='block'
            if (document.querySelector('[data-filter-2]') && this.sectionStyle !== 'one') document.querySelector('[data-filter-2]').style.display='block'

            if (filter1 && this.allFilter1.length){

                let html = `
					<div data-reset class="model-type ${!this.filtered1 ? 'active' : ''}" style="line-height: 1.5; font-size: .75rem; text-align: center; padding: 0.25em 0.75em; border-radius: 4px;">
						View All
					</div>
				`

                this.allFilter1.forEach(d => {
                    let name = this.types && this.types[d] && this.types[d].label || d
                    html += `
						<div data-value="${d}" class="model-type ${!this.filtered1 == d ? 'active' : ''}" style="line-height: 1.5; font-size: .75rem; text-align: center; padding: 0.25em 0.75em; border-radius: 4px;">
							${name}
						</div>
					`
                })

                filter1.innerHTML = html
                filter1.addEventListener('click', e => {
                    this.doFilter1(e.target)
                })
            }

            console.log('resized() from renderFilters')
            this.resized()
            this.allInitialized = true
        },
        async doLots(siteplan){
            let needRedo = false
            this.inactiveShapes = []
            this.shapes = {}
            let lots = {}
            this.allFilter1 = []
            this.allFilter2 = []

            let unitInstances = []



            if (siteplan.lots && siteplan.lots.length){

                // console.log('LOTTTTTTTTTT', this.plans.masterplan.lots)

                let filtersSofar = []

                siteplan.lots = siteplan.lots.map(lot => {

                    if (this.updatedLots[lot.id]) {
                        let updatedLot = this.updatedLots[lot.id]
                        lot.status = updatedLot.status
                        lot.tags = updatedLot.tags
                        needRedo = true
                        delete this.updatedLots[lot.id]


                    }
                    if (lot.tags && lot.tags.length){

                        lot.tags = lot.tags.map(x => {

                            if (x.id) return x.id
                            return x
                        })
                    }
                    if (lot.inherit && lot.inherit.id) {

                        let transformedLot = lot.inherit

                        if (this.updatedLots[lot.inherit.id] && this.updatedLots[lot.inherit.id].status){
                            let {name,status,attachedUnit,brick,inherit} = this.updatedLots[lot.inherit.id]
                            transformedLot = {
                                ...transformedLot,
                                name,status,attachedUnit,brick,inherit
                            }
                        }

                        if (transformedLot.tags && transformedLot.tags.length){
                            transformedLot.tags = transformedLot.tags.map(x => {
                                if (x.id) return x.id
                                return x
                            })
                        }
                        transformedLot.shapeId = lot.shapeId
                        // transformedLot.instance = lot.instance

                        transformedLot.tags = [...transformedLot.tags,...lot.tags]
                        return transformedLot
                    }
                    return lot

                }).filter(lot => {

                    let pass = true
                    if (lot.type === 'lot' && !this.showLots) return false
                    if (lot.type === 'info' && !this.showAmenity) return false

                    if (lot.type === 'lot') {
                        pass = Boolean(lot.units && lot.units.length)
                    }

                    return pass
                })


                siteplan.lots.forEach(lot => {
                    lot.filter1 = []
                    if (lot.siteplan && !this.socketIds[lot.siteplan]){
                        this.socketIds[lot.siteplan] = 1
                    }
                    let instanceName = this.instances[lot.instance] && this.instances[lot.instance].label || 'N/A'
                    lot.filter2 = [lot.instance]
                    lot.units = lot.units.map(unitId => {

                        let foundUnit = typeof unitId === 'string' ? this.units[unitId] : unitId

                        if (foundUnit && foundUnit.unitGroup) {
                            if (typeof foundUnit.unitGroup === 'string' && this.unitGroups[foundUnit.unitGroup]) foundUnit.unitGroup = this.unitGroups[foundUnit.unitGroup]
                            let uType = `${foundUnit.unitGroup && foundUnit.unitGroup.size}' ${foundUnit.unitGroup && foundUnit.unitGroup.type}`
                            if (!lot.filter1.includes(uType)) lot.filter1.push(uType)
                            filtersSofar.push(uType)
                            if (!unitInstances.includes(foundUnit.instance)) unitInstances.push(foundUnit.instance)
                            return foundUnit

                        }

                        return {}
                    }).filter(u => u.id)

                    lots[lot.shapeId] = lot
                })

            }

            if (siteplan.shapes && siteplan.shapes.length){

                siteplan.shapes.forEach(shape => {
                    shape.inactive = false
                    if (lots[shape.id]) {



                        shape.lot = lots[shape.id]
                        let lot = lots[shape.id]

                        if (lot.type === 'lot' && this.showTags && this.showTags.length){
                            let pass = false
                            let isTags =  this.showTags.some(tagId => {
                                pass = lot.tags.includes(tagId)
                            })
                            if (!pass)shape.inactive = true

                        }

                        if (shape.lot.status === 'hold' && hideHold) {
                            console.log('SETTING INACTIVE', shape.lot.id)
                            shape.inactive = true
                        }
                        if (shape.lot.filter1) {
                            shape.filter1 = shape.lot.filter1
                            let allFilter1 = [...this.allFilter1,...shape.filter1]
                            this.allFilter1 = Array.from(new Set(allFilter1))
                        }
                        if (shape.lot.filter2) {
                            shape.filter2 = shape.lot.filter2
                            let allFilter2 = [...this.allFilter2,...shape.filter2]
                            this.allFilter2 = Array.from(new Set(allFilter2))
                        }
                    }
                    if (shape.lot)  this.shapes[shape.id] = shape
                })
                this.inactiveShapes = siteplan.shapes.filter(x => x.inactive)
            }

            this.allFilter1 = this.allFilter1.sort()
            this.allFilter2 = this.allFilter2.sort()

			console.log('ALL FILTER 1', this.allFilter1)
			console.log('ALL FILTER 2', this.allFilter2)

            this.renderFilters()
            if (needRedo){
                setTimeout(() => {
                    this.anim.leader = ''
                    this.resized()
                }, 2000);
            }

        },
        doDimensions(){
            let parentElement = this.canvas.parentNode
            this.parentElement = parentElement
            let pWIDTH = parentElement.clientWidth
            let pHEIGHT = parentElement.clientHeight
            let aspectRatio,imageWidth,imageHeight,moveX = 0,moveY = 0
            let oldWIDTH = this.pWIDTH
            let oldHEIGHT = this.pHEIGHT

            if (this.fitSectionDOM){
                pHEIGHT = this.sectionDOM.clientHeight
            }

            aspectRatio = this.height / this.width
            imageWidth = pWIDTH
            imageHeight = pWIDTH * aspectRatio
            moveY = (pHEIGHT - imageHeight) / 2

            this.pWIDTH = pWIDTH
            this.pHEIGHT = pHEIGHT



            if (imageHeight > pHEIGHT){
                aspectRatio = this.width / this.height
                imageHeight = pHEIGHT
                imageWidth = pHEIGHT  * aspectRatio
                moveX = (pWIDTH - imageWidth) / 2
                moveY = 0
            }



            this.imageWidth = imageWidth
            this.imageHeight= imageHeight
            this.moveY = moveY
            this.moveX = moveX

            this.camera =  {...this.camera, x: this.pWIDTH/2, y: this.pHEIGHT/2,zoom:1, }
            this.originalCamera =  {x: this.pWIDTH/2, y: this.pHEIGHT/2}

            this.boundaries.top = this.camera.y - this.moveY
            this.boundaries.bottom = this.camera.y + this.moveY
            this.boundaries.left = this.camera.x - this.moveX
            this.boundaries.right = this.camera.x + this.moveX
        },
        layoutShapes(){
            Object.values(this.shapes).forEach(shape => {
                this.reCoordinate(shape)
                this.doPoints(shape)
            })
        },
        getThumbUrl(url,pre = 'thumbnail'){
            let imgSplit = url && url.split('/') || [];
            let error = false
            let string = imgSplit.map( (x,xI) => {

                let isLast = xI === imgSplit.length-1
                if (!isLast) return x

                let xLast = x.length
                if (x.indexOf('.jpg') + 4 === xLast || x.indexOf('.jpeg') + 5 === xLast || x.indexOf('.png') + 4 === xLast){
                    return `${pre}_${x}`
                } else {
                    error = true
                    return ''
                }

            }).join('/')

            return error ? '' : string
        },

        async initSiteplan(){

            let siteplan = this.plans[this.nowShowing] || {}
            let options = siteplan.options || {}
            this.instances = options.instances || {}
            this.types = options.types || {}
            if (siteplan.lots && siteplan.lots.length){

            }
            this.doLots(siteplan)
            if (!siteplan.shapes) siteplan.shapes = []
            this.siteplan = siteplan
            this.siteplanImage.onload = () => {
                this.start()
            }

            this.width = siteplan.width
            this.height = siteplan.height
            this.doDimensions()
            this.layoutShapes()


            this.doFilters()

            let siteplanBaseImage = siteplan.image

            this.thumbURL = this.getThumbUrl(siteplan.image,'large')

            if ( (this.tableMode || this.touchscreen) && (siteplan.options && siteplan.options.images && siteplan.options.images.hq && siteplan.options.images.hq.includes('://')) ){

                siteplanBaseImage = siteplan.options.images.hq

            } else if (this.thumbURL && this.thumbURL.length > 10 && !this.tableMode && !this.touchscreen){
                try{

                    this.isThumb = await this.isImage(this.thumbURL)
                    if (this.isThumb){

                        console.log('yes its a valid thumbnail ', this.isThumb)
                        siteplanBaseImage = this.thumbURL
                        let img = new Image()
                        img.onload = e => {
                            this.siteplanImage.src = siteplan.image
                        }
                        console.log('THUMB IS =>', this.isThumb)
                        img.src = siteplan.image
                    }

                }catch(err){
                    console.log('thumbnail not valid')
                }

            }


            this.siteplanImage.src = siteplanBaseImage;
            this.siteplanLoaded()


            // setTimeout(() => {
            // 	console.log('resized() from initSiteplan')
            //     this.resized()
            // }, 250);

        },
        siteplanLoaded(){
            this.sectionDOM.classList.remove("loading-sp")
            if(this.sectionDOM.querySelector('.loading-sp > .loader')){
                this.sectionDOM.querySelector('.loading-sp > .loader').remove();
            }
            if (this.initCallback){
                if (typeof this.initCallback === 'function') return this.initCallback(this.nowShowing, this)
                else if (typeof this.initCallback === 'string' && typeof window[this.initCallback] === 'function') window[this.initCallback](this.nowShowing)
            }
        },
        plotShape(shape) {

            let ctx = this.ctx
            let canvas = this.canvas

            if (!shape) return console.error('caannot find shape, might be deleted')

            ctx.beginPath();

            this.plotLines(shape)

        },
        initCanvas() {

            // this.sectionDOM.querySelectorAll('canvas').forEach(x => x.style.display = 'none')

            // let selector = `canvas[data-${this.showing}-canvas]`
            // console.log(`selector -> ${selector}`)

            let canvas = this.sectionDOM.querySelector('canvas')
            let sectionDOM = this.sectionDOM
            canvas.style.display = ''

            if (!canvas) return console.error('ERRORRR')

            let bar = document.querySelector('.siteplan-lot-wrapper')

            this.sectionDOM.classList.add(`siteplan-widget-style-${this.sectionStyle}`)

            if (bar){
                if (this.popup){
                    bar.classList.add('popup-style')
                } else {
                    if (this.leftBar){
                        bar.classList.add('left-side')
                    } else {
                        bar.classList.add('right-side')
                    }
                    let dom = sectionDOM.querySelector('.available-model-container')
                    if (dom){
                        dom.classList.add('space-y-3')
                    }
                }
            }

			let canvasContainer = document.querySelector('.siteplan-holder')
            let ctx = canvas.getContext('2d');
			canvas.width = canvasContainer.offsetWidth;
			canvas.height = canvasContainer.offsetHeight;
            this.ctx = ctx
            this.ctx.imageSmoothingEnabled = false;
            this.canvas = canvas
            return {canvas,ctx}


        },
        getEventLocation(e) {
            if (e.touches && e.touches.length <= 2){
                return { x:e.touches[0].clientX, y: e.touches[0].clientY }
            } else if (e.clientX && e.clientY){
                return { x: e.clientX, y: e.clientY }
            }
        },
        onPointerMove(e){

			e.preventDefault();
			e.stopPropagation();
			let { clientX, clientY} = e
			let self = this
			// if (Date.now() - this.touchStarted > 415) self.mouseOver = null
			self.mouseOver = null

			if (e.touches){

				// if (this.mobileMode && !this.touchscreen && e.touches.length !== 2 ) {
				// 	return e.stopPropagation()
				// }
				// if (e.touches.length === 2){
				// 	clientX = (e.touches[0].clientX + e.touches[1].clientX) /2
				// 	clientY = (e.touches[0].clientY + e.touches[1].clientY) /2
				// }

				// if (this.devDOM && e.touches.length === 2){
				// 	let touches = {
				// 		x1: e.touches[0].clientX,
				// 		y1:e.touches[0].clientY,
				// 		x2:e.touches[1].clientX,
				// 		y2:e.touches[1].clientY,
				// 	}
				// 	let iW = window.innerWidth
				// 	let iH = window.innerHeight
				// 	let hPerc = (Math.abs(touches.x1 - touches.x2) / iW) * 100
				// 	let vPerc = (Math.abs(touches.y1 - touches.y2) / iH) * 100
				// 	this.developerInfo = {
				// 		horizontal:hPerc,
				// 		vertical:vPerc,

				// 	}
				// 	this.updateDev()
				// }

				clientX = e.touches[0].clientX /2
				clientY = e.touches[0].clientY /2

				if (this.devDOM && e.touches.length === 1){
					let touches = {
						x1: e.touches[0].clientX,
						y1:e.touches[0].clientY
					}
					let iW = window.innerWidth
					let iH = window.innerHeight
					let hPerc = (touches.x1 / iW) * 100
					let vPerc = (touches.y1 / iH) * 100
					this.developerInfo = {
						horizontal:hPerc,
						vertical:vPerc,

					}
					this.updateDev()
				}

			}


			if (self.isMouseDown){
				self.isMousePan = true
				self.performedAction = true
			}


			// if (self.isMouseDown && self.camera.zoom !== 1){
			if (self.isMouseDown){
					self.pan.lastX = self.camera.x
					self.pan.lastY = self.camera.y

					let cX = self.getEventLocation(e).x/self.camera.zoom
					let cY = self.getEventLocation(e).y/self.camera.zoom

					self.camera.x = cX - self.dragStart.x
					self.camera.y = cY - self.dragStart.y

					if (this.devDOM){
						this.developerInfo['CMX'] = `${self.camera.x.toFixed(2)}, CMY:${self.camera.y.toFixed(2)}; MOUSE PAN? ${this.isMousePan}`
						this.updateDev()
					}

					if (self.updated.extendedHeight){

					} else{

						// if (self.camera.y <= self.boundaries.top){
						// 	self.camera.y = self.boundaries.top
						// 	self.dragStart.y = cY - self.camera.y
						// }
						// if (self.camera.y >= self.boundaries.bottom){
						// 	self.camera.y = self.boundaries.bottom
						// 	self.dragStart.y = cY - self.camera.y
						// }

					}

					if (self.updated.extendedWidth ){

					} else {

						// if (self.camera.x <= self.boundaries.left || self.camera.zoom === 1){

						// 	self.camera.x = self.boundaries.left
						// 	self.dragStart.x = cX - self.camera.x

						// }
						// if (self.camera.x >= self.boundaries.right || self.camera.zoom === 1){

						// 	self.camera.x = self.boundaries.right
						// 	self.dragStart.x = cX - self.camera.x

						// }
					}
			}else {
				self.isMousePan = false
				if (!this.touchscreen && !this.tableMode){
					self.virtualElement.getBoundingClientRect = self.generateGetBoundingClientRect(clientX, clientY - 20);
					self.popperInstance.update();
				}
			}



			var rect = e.target.getBoundingClientRect(),
				x = e.clientX - rect.left,
				y = e.clientY - rect.top,
				i = 0, r;

			self.mouseX = x
			self.mouseY = y

		},
        onPointerDown(e){
            let self = this

            this.touchInit = 0
            this.touchStarted = Date.now()
            var rect = e.target.getBoundingClientRect(),
                x = e.clientX - rect.left,
                y = e.clientY - rect.top,
                i = 0, r;
            self.mouseX = x
            self.mouseY = y

            if (e.touches){
                this.developerInfo['pointid'] = e.pointerId
                this.updateDev()
            }

            self.isMousePan = false
            self.mouseDownShape = self.mouseOver
            self.isMouseDown = true
            self.isMouseUp = false
            console.log('handle pinter DOWN', self.mouseOver)
            self.dragStart.x = self.getEventLocation(e).x/self.camera.zoom - self.camera.x
            self.dragStart.y = self.getEventLocation(e).y/self.camera.zoom - self.camera.y

        },
        onPointerUp(e){
            let self = this
            this.touchEnded = Date.now()
            this.touchDuration = this.touchEnded - this.touchStarted
            console.log('POINTER UP DURATION',this.touchDuration)
            if (this.devDOM){
                this.developerInfo['PointerUp'] = true
                if (e.touches){
                    this.developerInfo['pointid'] = e.pointerId
                }
                this.updateDev()
            }

            self.isMouseUp = true
            console.log('LINE 926')
            if (!self.isMousePan || self.touchDuration < 415){
                console.log('LINE 928')

                if (self.mouseOver && self.mouseOver.lot && !self.static){
                    console.log('CLICKEDON ', self.mouseOver)
                    if (self.mouseOver.lot.type === 'lot' && !self.mouseOver.lot.status.includes('sold') && self.mouseOver.lot.status !== 'hold' && self.mouseOver.lot.status != 'inventory' && self.mouseOver.lot.status != 'notreleased'){
                        console.log('HEYYYYY',self.mouseOver.lot.status)
                        if (self.mouseOver.lot.units && self.mouseOver.lot.units.length){
                            self.showLotInfo(self.mouseOver.lot)
                        }
                    } else if  (self.mouseOver.lot.type === 'info') {
                        self.showAmenity(self.mouseOver.lot)
                    }
                } else {
                    this.onEvent('TAP')
                }
            }
            self.isMousePan = false
            self.isMouseDown = false
            self.mouseOver = null
            self.mouseX = null
            self.mouseY = null



        },
        async showAmenity(lot){

            let self = this
            if (!self.siteplanOverlay) this.siteplanOverlay = document.querySelector('.siteplan-overlay-wrapper')

            if (self.siteplanOverlay){
                self.siteplanOverlay.querySelector('[data-selected-lot-name]').innerHTML = lot.name
                let html = `
                    <div class="siteplan-unit-list-item">
                        <div class="siteplan-unit-img-holder">
                            <img src="{{thumbnail}}" />

                        </div>
                        <div class="text-center space-y-1 mt-3">
                            <p class="text-xs">{{name}}</p>
                            <h5 class="text-sm">{{uG.name}} {{unit.name}}</h5>
                            <p class="text-sm mb-3">
                                {{description}}
                            </p>
                        </div>
                    </div>
                `

                self.siteplanOverlay.classList.add('show-mask')
                self.siteplanOverlay.querySelector('.available-model-container').innerHTML = await $parse(html,lot)

                const imgLoad = imagesLoaded( self.siteplanOverlay.querySelector('.available-model-container') )
                console.log('ADDING SHOW-mask',self.siteplanOverlay.classList)

                imgLoad.on( 'done',  instance => {
                    self.siteplanOverlay.classList.add('show-mask')
                    self.siteplanOverlay.classList.add('show-panel')
                    self.siteplanOverlay.classList.add('show-amenity')
                })
            }
            if (!self.siteplanOverlayMask) {
                self.siteplanOverlayMask = document.querySelectorAll('.siteplan-overlay-close')
                self.siteplanOverlayMask.forEach(dom => dom.addEventListener('mouseup', e => {
                        self.siteplanOverlay.classList.remove('show-mask')
                        self.siteplanOverlay.classList.remove('show-panel')
                        self.siteplanOverlay.classList.remove('show-amenity')
                }))
            }
        },
        async showLotInfo(lot){
            let self = this
            this.showingLot = lot.id
            console.log('SHOWING LOT', this.onEvent)
            this.onEvent('SHOW_LOT',lot)
            if (!self.siteplanOverlay) this.siteplanOverlay = document.querySelector('.siteplan-overlay-wrapper')
            if (this.showOnlyModel && lot.units && lot.units.length === 1 && this.openModel){
                return this.openModel(lot.units[0])
            }
            if (self.siteplanOverlay){
                self.siteplanOverlay.querySelector('[data-selected-lot-name]').innerHTML = lot.name + `${lot.block ? ` (Block ${lot.block})` : ''}`
                let linkTemplate = this.linkTemplate || '/modeldetails/{{unit.iSlug}}-{{unit | unitSlug}}'
                let priceLine = '<h5 class="text-sm text-gray-600">{{unit.package.price | currency}}</h5>'
                if (this.hidePrices) priceLine = ''
                let html = `
                    {% for unit in units %}
                        {% assign uG = unit.unitGroup %}


                        {% if clickable %}
                            <a href="${linkTemplate}" class="siteplan-unit-list-item flex flex-col justify-between">
                        {% else %}
                            <div class="siteplan-unit-list-item flex flex-col justify-between">
                        {% endif %}


                            <div class="siteplan-unit-img-holder flex-1 flex items-center">
                                <img src="{{unit.image}}" />
                                <div>
                                    <button>VIEW MODEL DETAILS</button>
                                </div>
                            </div>
                            <div class="text-center space-y-1 mt-3">
                                <p class="text-xs">{{uG.type}} {{uG.size}}</p>
                                <h5 class="text-sm">{{uG.name}} {{unit.name}}</h5>
                                ${priceLine}
                                <p class="text-sm mb-3">
                                    {{unit.package.beds}} Beds, {{unit.package.baths}} Baths, {{unit.package.sqft | sqft}}
                                </p>
                            </div>

                        {% if clickable %}
                            </a>
                        {% else %}
                            </div>
                        {% endif %}

                    {% endfor %}


                    `

                self.siteplanOverlay.classList.add('show-mask')
                document.body.style.overflow = 'hidden'
                lot.units.forEach(unit => {
                    unit.iSlug = this.iSlugs[unit.instance] || ''
                })
                self.siteplanOverlay.querySelector('.available-model-container').innerHTML = await $parse(html,{...lot,clickable:this.enableLink})
                const imgLoad = imagesLoaded( self.siteplanOverlay.querySelector('.available-model-container') )
                console.log('ADDING SHOW-mask',self.siteplanOverlay.classList)
                if (this.sectionDOM.querySelector('.siteplan-lot-content-outer')){
                    this.sectionDOM.querySelector('.siteplan-lot-content-outer').scrollTo(0,0)
                }
                imgLoad.on( 'done',  instance => {
                    self.siteplanOverlay.classList.add('show-mask')
                    self.siteplanOverlay.classList.add('show-panel')
                })

            }
            if (!self.siteplanOverlayMask) {
                self.siteplanOverlayMask = document.querySelectorAll('.siteplan-overlay-close')
                self.siteplanOverlayMask.forEach(dom => dom.addEventListener('mouseup', e => {
                        self.siteplanOverlay.classList.remove('show-mask')
                        self.siteplanOverlay.classList.remove('show-panel')
                        self.siteplanOverlay.classList.remove('show-amenity')
                        document.body.style.overflow = 'auto'
                }))
            }

        },
        handleTouch(e, singleTouchHandler){
			if ( !e.touches || e.touches.length == 1 ) {
				if (!e.hasOwnProperty('clientX')){
					if (e.touches){
						e.clientX = e.touches[0].clientX
						e.clientY = e.touches[0].clientY
					}
				}
				singleTouchHandler(e)
			} else if (e.type == "touchmove" && e.touches.length == 2) {
				isDragging = false
				this.handlePinch(e)
			}
		},
		handlePinch(e){
			e.preventDefault()

			let touch1 = { x: e.touches[0].clientX, y: e.touches[0].clientY }
			let touch2 = { x: e.touches[1].clientX, y: e.touches[1].clientY }

			// This is distance squared, but no need for an expensive sqrt as it's only used in ratio
			let currentDistance = (touch1.x - touch2.x)**2 + (touch1.y - touch2.y)**2

			if (this.initialPinchDistance == null){
				this.initialPinchDistance = currentDistance
			} else {
				this.adjustZoom( null, currentDistance/this.initialPinchDistance )
				this.initialPinchDistance = currentDistance
			}
		},
        adjustZoom(zoomAmount, zoomFactor){
			console.log('ZOOM AMOUNT', zoomAmount)
			console.log('ZOOM FACTOR', zoomFactor)
			let self = this
			if (self.zooming && !self.mobileMode) return console.error('still zooming')


			if (!this.isMousePan){

				if (zoomAmount){
					let zoom = Math.min(self.camera.zoom + zoomAmount, self.MAX_ZOOM)
					zoom = Math.max(zoom, self.MIN_ZOOM)
					self.camera.nextZoom = zoom
				} else if (zoomFactor){
					console.log(zoomFactor)
					let zoom = Math.min(zoomFactor*self.camera.zoom, self.MAX_ZOOM)
					zoom = Math.max(zoom, self.MIN_ZOOM)
					self.camera.nextZoom = zoom
					self.performedAction = true
				}

				// self.camera.nextZoom = Math.min( self.camera.nextZoom, self.MAX_ZOOM )
				// self.camera.nextZoom = Math.max( self.camera.nextZoom, self.MIN_ZOOM )


			}

		},
        zoomReset(){

            let self = this
            if (!self.zooming) {
                console.log('RESETTTTINGGGGGGGG')
                this.developerInfo['RESET'] = 'true'
                this.updateDev()
                self.camera = {
                    ...self.camera,
                    x:self.originalCamera.x,
                    y:self.originalCamera.y,
                    zoom:1,
                    nextZoom:1,
                }

            }
        },
        zoomIn(){
            delete this.developerInfo['RESET']
            this.updateDev()
            let self = this
            if (!self.zooming) self.adjustZoom(0.4)
        },
        zoomOut(){
            delete this.developerInfo['RESET']
            this.updateDev()
            let self = this
            console.log('ZOOM OUT', self.camera.zoom - 0.4)
            if (!self.zooming) self.adjustZoom(-0.4)
        },
        zoomWheel(amount){
            let self = this
            self.adjustZoom(amount)
        },
        responsive(){
            let widgetDOM = this.sectionDOM
            let bar
            if (widgetDOM){
                bar = widgetDOM.querySelector('.siteplan-lot-wrapper')
            }

            if (!bar) return console.error('no bar found')

            let classes = Array.from(bar.classList)

            if (this.mobileMode){
                widgetDOM.classList.add('mobile-mode')
                if (classes.includes('popup-style')){
                    bar.classList.remove('popup-style')
                    if (this.leftBar){
                        bar.classList.add('left-side')
                    } else {
                        bar.classList.add('right-side')
                    }
                }
            } else {
                widgetDOM.classList.remove('mobile-mode')
                if (this.popup){
                    bar.classList.remove('left-side')
                    bar.classList.remove('right-side')
                    bar.classList.add('popup-style')
                }
            }

        },
        toggleMobileFilter(e){
            this.sectionDOM.classList.toggle('show-mobile-filters')
            if (Array.from(this.sectionDOM.classList).includes('show-mobile-filters')){
                document.body.style.overflow = 'hidden'
            } else {
                document.body.style.overflow = 'auto'
            }
        },
        resized(e){
            console.log('RESETTINGGGGG (resized fn)')
            let self = this
            delete this.developerInfo['RESET']
            this.developerInfo = {'RESIZED':true}

            self.dotRadius = 0
            if (self.canvas){
                if (window.innerWidth < 1024){
                    this.mobileMode = true
                } else {
                    this.mobileMode = false
                }
                console.log('MOVILE MODE', this.mobileMode)
                self.responsive()
                self.doDimensions()
                self.layoutShapes()

                if (window.innerWidth < 500 ){
                } else if (!this.zooming && this.allInitialized){
                    self.zoomReset()
                }
                self.doFilters()

            }

        },
        updateDev(){
            if (!this.devDOM) return
            let html = ''
            Object.entries(this.developerInfo).forEach( ([key,value]) => {
                html += `<span>${key}: ${value}</span> | `
            })
            this.devDOM.innerHTML = html
        },
        initListeners(){
			let canvas = this.canvas

			canvas.style.touchAction="none"
			this.sectionDOM.style.touchAction="none"

			this.sectionDOM.addEventListener('contextmenu', e => {
				e.preventDefault();
			})

			this.virtualElement = {
				getBoundingClientRect: this.generateGetBoundingClientRect(),
			}


			const tooltip = document.querySelector('#siteplan-lot-tooltip');
			// this.popperInstance = Popper.createPopper(this.virtualElement, tooltip);
			this.popperInstance = createPopper(this.virtualElement, tooltip);

			let pointerDown = 'ontouchstart' in document ? 'touchstart' : 'onpointerdown' in document ? 'pointerdown' : 'mousedown';
			let pointerUp = 'ontouchend' in document ? 'touchend' : 'onpointerup' in document ? 'pointerup' :  'mouseup';
			let pointerMove = 'ontouchmove' in document ? 'touchmove' : 'onpointermove' in document ? 'pointermove' :  'mousemove';
			let pointerCancel = 'ontouchcancel' in document ? 'touchcancel' : 'pointercancel';

			// console.log('OPINTER DOWN', pointerDown)
			// console.log('OPINTER move', pointerMove)

			canvas.addEventListener('mouseout', e => {
			})
			canvas.addEventListener('mouseenter', e => {
			})

			canvas.addEventListener(pointerDown, (e) => this.handleTouch(e, this.onPointerDown.bind(this)))
			canvas.addEventListener(pointerUp,  (e) => {
				console.log('Pointer up')
				this.initialPinchDistance = null
				this.sectionDOM.classList.remove('one-touch')
				if ((!e.touches || e.touches.length <= 1)) {
					this.onPointerUp(e)
				}
			})
			canvas.addEventListener(pointerCancel,  (e) => {
				console.log('%c POINTER CANCELLED', 'color:red')
			})
			canvas.addEventListener(pointerMove,  (e) => {

				this.isMousePan = true

				if (e.touches && e.touches.length == 1 && this.mobileMode && !this.touchscreen) {
					// this.sectionDOM.classList.add('one-touch')
					this.developerInfo['Touch'] = 'One F'
					this.onPointerMove(e)
					this.updateDev()

				} else if (e.touches && e.touches.length == 2 && this.mobileMode && !this.touchscreen) {
					this.isMousePan = false
					this.performedAction = true
					this.handlePinch(e)
				} else {
					// this.sectionDOM.classList.remove('one-touch')
					this.onPointerMove(e)
				}
			})
			// canvas.addEventListener(pointerMove, (e) => { e.preventDefault(); this.handleTouch(e, this.onPointerMove.bind(this))})

			window.addEventListener('resize', e => this.debounceFunction(e,this.resized.bind(this),1000))

			if (this.mouseWheelZoom){
				canvas.addEventListener( 'wheel', (e) => this.zoomWheel(e.deltaY*this.SCROLL_SENSITIVITY))
			}

			let zoomContainer = this.parentElement.querySelector('[data-s-tool-container]')
			let filterCloseBtn = this.sectionDOM.querySelector('.filter-close-btn svg')

			if (filterCloseBtn){
				filterCloseBtn.addEventListener(pointerUp,  (e) => { if (!e.touches || e.touches.length <= 1) this.toggleMobileFilter(e) })
			}
			if (zoomContainer){
				let zoomIn = zoomContainer.querySelector('[data-s-tool=in]')
					,zoomReset = zoomContainer.querySelector('[data-s-tool=reset]')
					,zoomOut = zoomContainer.querySelector('[data-s-tool=out]')
					,filterBtn = zoomContainer.querySelector('[data-s-tool=filter]')


				if (zoomIn) {
					zoomIn.addEventListener(pointerUp,  (e) => { if (!e.touches || e.touches.length <= 1) this.zoomIn(e) })
				}
				if (zoomReset) {
					zoomReset.addEventListener(pointerUp,  (e) => { if (!e.touches || e.touches.length <= 1) this.zoomReset(e) })
				}

				if (zoomOut) {
					zoomOut.addEventListener(pointerUp,  (e) => { if (!e.touches || e.touches.length <= 1) this.zoomOut(e) })
				}

				if (filterBtn){
					filterBtn.addEventListener(pointerDown,  (e) => { if (!e.touches || e.touches.length <= 1) this.toggleMobileFilter(e) })

				}

			}

			let filterLabel = this.sectionDOM.querySelector('[data-filter-label]')
			let filterDropdown = this.sectionDOM.querySelector('[data-filter-dropdown]')

			if (filterLabel){
				filterLabel.addEventListener(pointerUp,  (e) => {
					if (!e.touches || e.touches.length <= 1){
						filterDropdown.style.display = filterDropdown.style.display === 'block' ? 'none' : 'block';
					}
				})
			}

			// canvas.addEventListener("keydown", event => {
			//     console.log('KEYYYY UP', event.keyCode)
			//     // if (event.isComposing || event.keyCode === 229) {
			//     //     return;
			//     // }
			// });
			// canvas.addEventListener("keyup", event => {
			//     if (event.isComposing || event.keyCode === 229) {
			//         return;
			//     }
			//     console.log('KEYYYY UP', event.keyCode)

			// })

		},
        generateGetBoundingClientRect(x = 0, y = 0) {
            return () => ({
                width: 0,
                height: 0,
                top: y,
                right: x,
                bottom: y,
                left: x,
            });
        },
        recalc(){

            let iw = this.imageWidth * this.camera.zoom
            let ih = this.imageHeight * this.camera.zoom
            let it = ih
            let ib = ih
            let extendedWidth = iw > this.pWIDTH
            let extendedHeight = ih > this.pHEIGHT



            this.updated = {
                it,
                ib,
                iw,
                ih,
                extendedWidth,
                extendedHeight
            }
        },
        drawShapes(){
            if (!this.allInitialized) return

            let self = this
            let shapes = this.filteredShapes
            let canvas = self.canvas
            let ctx = self.ctx
            if (!shapes && !shapes.length) return console.error('something happened')
            canvas.width = this.pWIDTH
            canvas.height = this.pHEIGHT

            // Translate to the canvas centre before zooming - so you'll always zoom on what you're looking directly at
            this.zooming = false

            if (this.camera.nextZoom !== this.camera.zoom) this.zooming = true
            if (this.camera.nextZoom > this.camera.zoom){
                console.log('zooming In ', this.camera.zoom)
                if (this.camera.nextZoom - this.camera.zoom < 0.2) this.camera.zoom = this.camera.nextZoom
                else {
                    this.camera.zoom += .15
                }
            } else if (this.camera.nextZoom < this.camera.zoom){

                if (this.camera.zoom - this.camera.nextZoom < 0.2) this.camera.zoom = this.camera.nextZoom
                else {
                    this.camera.zoom -= .15
                }
            }

            ctx.translate( this.pWIDTH / 2, this.pHEIGHT / 2 )
            ctx.scale(self.camera.zoom, self.camera.zoom)
            ctx.translate( -this.pWIDTH * 1 + self.camera.x, -this.pHEIGHT * 1 + self.camera.y )
            ctx.clearRect(0, 0, this.pWIDTH, this.pHEIGHT);


            self.ctx.drawImage(self.siteplanImage,this.moveX,this.moveY,self.imageWidth,self.imageHeight)

            Object.values(shapes).forEach(shape => {
                if (shape.lot && shape.lot.id){
                    if (self.updatedLots[shape.lot.id]){
                        let {name,status,attachedUnit,brick,inherit} = self.updatedLots[shape.lot.id]
                        shape.lot = {
                            ...shape.lot,
                            name,status,attachedUnit,brick,inherit
                        }
                    }
                }
                this.plotShape(shape)
            })


            if (document.querySelector('#siteplan-lot-tooltip') && !this.mobileMode && !this.touchscreen && !this.tableMode){
                let tt = document.querySelector('#siteplan-lot-tooltip')
                let text = document.querySelector('#siteplan-lot-tooltip span')
				let textContainer = document.querySelector('#siteplan-lot-tooltip div')
                if (this.mouseOver){
                    tt.style.opacity = 1
					let label = ''
					if (this.mouseOver.lot.type === 'lot'){
						label = 'Lot ' + this.mouseOver.lot.name + `${this.mouseOver.lot.block ? ` (Block ${this.mouseOver.lot.block})` : ''}` + ' - ' + this.statusNames[this.mouseOver.lot.status]
						textContainer.style.backgroundColor = this.colorLookup[this.mouseOver.lot.status] || 'rgba(255,255,255,0)'
					} else {
						label = this.mouseOver.lot.name
						textContainer.style.backgroundColor = this.colorLookup['available'] || 'rgba(255,255,255,0)'
					}
                    text.innerHTML = label

                } else {
                    this.canvas.style.cursor = 'inherit'
                    tt.style.opacity = 0
                }
            }

            // for (const key in shapes) {
            //     if (Object.hasOwnProperty.call(shapes, key)) {
            //         const shape = shapes[key];


            //     }
            // }

        },
        start(){
            this.analyzeLots()

            const draw = () => {
                this.recalc()
                this.drawShapes()
                if (this.hasError) return
                requestAnimationFrame( draw )
            }
            if (!this.alreadyListening){
                draw()
                this.initListeners()
                this.initSocket()
                this.alreadyListening = true
            }

        },
        updateLot(e){
            this.updatedLots[e.id] = e

            let siteplan = this.plans[this.nowShowing] || {}
            this.doLots(siteplan)


        },
        initSocket(){
            console.log('STARTING SOCKET', this.isSocket)
            if (!this.isSocket || typeof io === 'undefined') return

            Object.keys(this.socketIds).forEach( key => {

                this.sockets[key] = io(`https://ws.bildhive.com`,{query:{id:key}});
                let socket = this.sockets[key]
                socket.on("connect", () => {
                    console.log(`[${key}] is it discocnnected? => `,socket.disconnected); // false
                });
                socket.on("updateLot", (e) => {
                    console.log(`[${key}] update LOT CALLED FROM SERVER? => `,e);
                    this.updateLot(e)
                });

                socket.on("connect_error", (error) => {
                    // ...
                    console.error(`[${key}] CONNECT ERRORRRR`, error)
                });
                socket.on("error", (error) => {
                    console.error(`[${key}] ERRORRRR`, error)
                });
                console.log('SOCKET CONNECTEDDDDDDD ', key, ' => ', socket)
            })

        },
        async toTab(clickedOn){
            let tabsDOM = this.sectionDOM.querySelector('[data-tab-wrapper]')
            let tabDOMs = this.sectionDOM.querySelectorAll('[data-tab-menu]')
            this.nowShowing = clickedOn
            await this.initSiteplan()
            tabDOMs.forEach(x => {
                if (clickedOn === x.dataset.tabMenu){
                    x.classList.add('bg-white')
                    x.classList.add('text-black')
                    x.classList.remove('hover:bg-gray-400')
                } else {
                    x.classList.remove('bg-white')
                    x.classList.remove('text-black')
                    x.classList.add('hover:bg-gray-400')
                }

            })

        },
        async doTabs(){
            let tabsDOM = this.sectionDOM.querySelector('[data-tab-wrapper]')
            let tabDOMs = this.sectionDOM.querySelectorAll('[data-tab-menu]')
            if (this.masterTab && tabsDOM){
                tabsDOM.classList.remove('hidden')
                tabDOMs.forEach(dom => {
                    dom.addEventListener('click', async e => {
                        let clickedOn = e.target.dataset.tabMenu
                        let showing = this.showing
                        if (clickedOn !== showing){
                            this.showing = clickedOn
                            this.toTab(clickedOn)

                        }
                    })
                })

            }
        },
        addTime(addMins = 0){
            let add = addMins * 60000
            time = Date.now() + add
            return new Date(time).getTime()
        },
        highlightLots(lots){
            let shapes = []
            lots.forEach(x => shapes.push(x.shapeId))
            this.highlightShapes = shapes
        },
        getSiteplan(){

            return new Promise( (resolve,reject) => {
                if (spData && spData.siteplan && spData.siteplan.id) return resolve(spData)
                console.log('SPDATA', spData)
                let url = this.spURL
                const getApi = (res) => {
                    fetch(url)
                        .then( res => res.json()).then(data => {


							console.log('SPDATA DATA COMING BACK', data)
                            return res(data)

                        }).catch(reject)
                }


                return getApi(resolve)

            })

        },
		getArchitecturalRules(unitId){
			return new Promise( (resolve,reject) => {
				if(!this.plans.siteplan){
					return;
				}
                let url = `${this.apiURL}/v1/lots-by-unit/${this.plans.siteplan.id}/${unitId}?token=${this.instance}`
                const getApi = (res) => {
                    fetch(url)
                        .then( res => res.json()).then(data => {
							console.log('ARCHITECTURAL RULES DATA', data)
                            return res(data)
                        }).catch(reject)
                }


                return getApi(resolve)

            })
		},
        async init({
            pulsateDots=0,
            hideColor,
            hideAvail=0,
            isStatic = 0,
            openModel,
            showOnlyModel = 0,
            noPanZoomedOut = 0,
            touchscreen = false,
            fitSectionDOM = false,
            tableMode = false,
            socket = false,
            hideFilter = false,
            hideDots = false,
			hideStatus = false,
            hidePrices = true,
            cb,
            linkTemplate = '',
            sectionDOM = '',
            show='siteplan',
            tabs = {siteplan:'',masterplan:''},
            instance,
			selectedStatuses = [],
            masterTab = false,
            showTags = [],
            devel = 0,leftBar = false,
            filterStyle = 'bottom',
            style = 'one',
            popup = false,
            enableLink = true,
			fontSize = 3,
			hideHold = false,
            initialZoom = 1}){


            if (initialZoom){
                this.initialZoom = initialZoom
                this.camera.nextZoom = initialZoom
            }
            this.pulsateDots = pulsateDots
            this.static = isStatic
            this.openModel = openModel
            this.noPanZoomedOut = noPanZoomedOut
            this.showOnlyModel = showOnlyModel
            this.touchscreen = touchscreen
            this.fitSectionDOM = fitSectionDOM
            this.isSocket = socket
            this.hidePrices = hidePrices
            this.enableLink = enableLink
            this.popup = popup
            this.sectionStyle = style
            this.filterStyle = filterStyle
            this.leftBar = leftBar
            this.devel = location.href.includes('bhdeveloper=1') ? 1 : devel
            this.showTags = showTags
            this.instance = instance
			this.selectedStatuses = selectedStatuses
            this.masterTab = masterTab
            this.tabs = tabs
            this.showing = show
            this.linkTemplate = linkTemplate
            this.initCallback = initCallback || cb
            this.onEvent = onEvent
			this.onFilter = onFilter
            this.hideDots = hideDots
			this.hideStatus = hideStatus
            this.tableMode = tableMode
            this.sectionDOM = dom
            this.hideHold = hideHold
            this.hideAvail = hideAvail
			this.fontSize = fontSize
            if (hideColor) this.hideColor = hideColor

            if (typeof dom === 'string'){
                this.sectionDOM = document.querySelector(dom)
                if (!dom) this.sectionDOM = document.querySelector('#'+dom)
                if (!dom) this.sectionDOM = document.querySelector('.'+dom)
            }

            if (!this.sectionDOM || !this.sectionDOM.tagName) return console.error('no dom found')

            if (this.devel) {
                // if (this.sectionDOM.querySelector('.developer-test')){
                //     this.devDOM = this.sectionDOM.querySelector('.developer-test')
                //     this.devDOM.classList.add('show-dev')
                // }
            }

            // if (tableMode){
            //     this.sectionDOM.classList.add('table-mode')
            // }
            // if (hideFilter){
            //     this.sectionDOM.classList.add('hide-filter')
            // }

            let url = `${this.apiURL}/b1/siteplan?token=${this.instance}&both=${this.masterTab? '1' : '0'}`

            // if (sessionStorage.sp_api && sessionStorage.sp_api === 'v1'){
            //     url = `${this.apiURL}/v1/siteplan?token=${this.instance}&both=${this.masterTab? '1' : '0'}`
            // }

            this.spURL = url


            await this.getSiteplan().then( async data => {
                this.units = {}
                if (data.units && data.units.length){

                    data.units.filter(x => x.unitGroup && x.packages).map(x => {
                        x.package = x.packages[0]
                        return x
                    }).forEach(x => {
                        this.units[x.id] = x
                    })
                    if (!data.instances) data.instances = []

                    if (data.unitGroups && data.unitGroups.length){
                        let uG = {}
                        data.unitGroups.forEach( item => {
                            uG[item.id] = item
                        })
                        this.unitGroups = uG
                    }

                }

                this.plans = {
                    masterplan:data.masterplan || {},
                    siteplan:data.siteplan || {}
                }

                this.nowShowing = 'siteplan'
                this.initCanvas()
                await this.initSiteplan()
                this.doTabs()

            })


        },
		async refreshInit(){
			this.initCanvas()
			await this.initSiteplan()
			this.doTabs()
		}

    }


    if (options && Object.keys(options).length){
        drawLot.init({
            ...options
        })
    }
    return drawLot
}


export default siteplanApp
// window.siteplanApp = siteplanApp
